import React from 'react';
import {BsLinkedin, BsGithub, BsMedium} from 'react-icons/bs';

const ProfileHeaderSocial = () => {
  return (
    <div className="header__socials">
     <a href='https://www.linkedin.com/in/vijay-m-3566b9127/' target="_blank" rel="noreferrer"><BsLinkedin size={'25px'}/></a>
     <a href='https://github.com/vijay0297' target="_blank" rel="noreferrer"><BsGithub size={'25px'}/></a>
     <a href='https://medium.com/@vijaymanoharan02' target="_blank" rel="noreferrer"><BsMedium size={'25px'}/></a>
    </div>
  );
}

export default ProfileHeaderSocial