import React from 'react';
import './about.css';
import ME from '../../assets/vijayMainImage.png';
import {FaAward} from 'react-icons/fa';
import {VscFolderLibrary} from 'react-icons/vsc';

const About = () => {
  return (
    <section id='about'>
    <h2>About</h2>
    <div className="container about__container">
      {/**
        <div className="about__me">
          <div className='about__me-image'>
            <img src={ME} alt="About" />
          </div>
        </div> 
      */}
      <div className="about__content">
        <div className="about__cards">
          <article className='about__card'>
            <FaAward class='about__icon'/>
            <h5>Exprience</h5>
            <small>4+ Years</small>
          </article>
          <article className='about__card'>
            <VscFolderLibrary class='about__icon'/>
            <h5>Projects</h5>
            <small>20+ Completed</small>
          </article>
        </div>
        <p>
          Hello there, my name is Vijay. Welcome to my website. Nice to meet you all. 
        </p>
        <a href = "#contact" className='btn btn-primary' id='lets_talk'> Let's Talk</a>
      </div>
    </div>
    </section>
  )
}

export default About;