import { useState } from "react";
import { getPokemonByName } from '../../services/pokemon';
import './pokemon.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Pokemon = () => {
  const [data, setData] = useState();
  const [name, setName] = useState();
  const [types, setTypes] = useState();
  const [ability, setAbility] = useState();
  

  const onChangeHander = () => {}
  const onKeyDown = (e) => {
    if(e.key === 'Enter') {
      onSearch();
    }
  }
  const onSearch = async () => {
    const pokemonName = document.getElementById('search-bar').value.toLowerCase();
    console.log(pokemonName);
    await getPokemonByName(pokemonName)
    .then((response) => {
      if (response) {
        setData(response);
        if (response.name) {
          setName(name);
        }
        let types = '';
        for (let index = 0; index < response.types.length; index++) {
          types += response.types[index].type.name.charAt(0).toUpperCase() + response.types[index].type.name.slice(1);
          if(index !== response.types.length - 1) {
            types += ', ';
          }
        }
        setTypes(types);
        setAbility(response.abilities[0].ability.name);
      }
    })
    .catch((err) => {toast("Oops, Pokemon is `Unown`, 😜 ");});
  }

  return (
    <><section className="pokemon" id="#pokemon">
      <form>
        <input
          id="search-bar"
          type={'text'}
          onChange={() => { onChangeHander(); } }
          className='searc-bar'
          placeholder="Search by pokemon name"
          onKeyDown={(e) => { onKeyDown(e); } }
        ></input>
        <div className="search-button">
          <button className='search-btn' onClick={() => { onSearch(); } } id='search-btn'>Get Data</button>
        </div>
      </form>
      <div className="pokemon_data">
        <div id="pokemon_image_block" className="pokemon_image_block">
          {data && data.sprites &&
            <img
              src={data.sprites.other.dream_world.front_default}
              alt="pokemon_image"
              id='pokemon_image'>
            </img>}
        </div>
        <div className="pokemon_details">
          {data && data.name !== 'AxiosError' &&
            <ol>
              <li>
                <label className="pokemon_label" htmlFor="pokemon_name">Name: </label>
                <span id="pokemon_name">{data && data.name}</span>
              </li>
              <li>
                <label className="pokemon_label" htmlFor="pokemon_type">Type: </label>
                <span id="pokemon_type">{data && types}</span>
              </li>
              <li>
                <label className="pokemon_label" htmlFor="pokemon_ability">Ability: </label>
                <span id="pokemon_ability">{data && ability}</span>
              </li>
            </ol>}
        </div>

      </div>
    </section><ToastContainer 
      position="top-center"
      theme="dark"
      autoClose={3000}
      hideProgressBar={true}
    /></>
  );
}

export default Pokemon;
