/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import '../../styles/navigation.css';
import {AiOutlineHome, AiOutlineUser, AiOutlineQq} from 'react-icons/ai';
import { BiBook} from 'react-icons/bi';
import { RiServiceLine} from 'react-icons/ri';

const Navigation = () => {

  const [activeNav, setActiveNav] = useState("#");

  const onClickActions = (value) => {
    setActiveNav(value);
  }
  const getActiveClassName = (className) => {

    return activeNav ===  className ? '' : '';
  }
  return (
    <nav>
      <a href='/' onClick={ () => setActiveNav('#')} className={getActiveClassName('#')}><AiOutlineHome/></a>
      <a href='/pokemon2' onClick={ () => setActiveNav('#pokemon2')} className={getActiveClassName('#pokemon2')}><AiOutlineQq/></a>
      {/*<a href='/about' onClick={ () => onClickActions('#about')} className={getActiveClassName('#about')}><AiOutlineUser/></a>
      <a href='#experience' onClick={() => onClickActions('#experience')} className={getActiveClassName('#experience')}><BiBook/></a>
      <a href='#works' onClick={() => onClickActions('#works')} className={getActiveClassName('#works')}><RiServiceLine/></a>*/}
    </nav>
  )
}

export default Navigation;