import React from 'react';
import '../../styles/profileHeader.css';
import CTA from './CTA';
import PROFILEIMAGE from '../../../src/assets/vijayImage.png';
import HeaderProfileSocial from './ProfileHeaderSocial';

const ProfileHeader = () => {
  return (
    <header>
      <div className='container header__container'>
      <h1>Vijay Manoharan</h1>
      <h5 className="text-light">Software Developer</h5>
      {/**<CTA></CTA> **/}
      <HeaderProfileSocial/>
      <div className="profileinage">
        <img src={PROFILEIMAGE} alt="me"></img>
      </div>
      {/**<a href="#contact" className='scroll__down'> Scroll down </a> **/}
      </div>
    </header>
  )
}


export default ProfileHeader