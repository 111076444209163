import './search-bar.css';
import { useState } from "react";
import { getPokemonByName } from '../../services/pokemon';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Pokemon2 = () => {

  const [data, setData] = useState();
  const [name, setName] = useState();
  const [types, setTypes] = useState();
  const [ability, setAbility] = useState();
  

  const onChangeHander = () => {}
  const onKeyDown = async(e) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      await onSearch(e);
    }
  }
  const onSearch = async (e) => {
    e.preventDefault();
    const pokemonName = document.getElementById('search').value.toLowerCase();
    await getPokemonByName(pokemonName)
    .then((response) => {
      if (response) {
        setData(response);
        if (response.name) {
          setName(name);
        }
        let types = '';
        for (let index = 0; index < response.types.length; index++) {
          types += response.types[index].type.name.charAt(0).toUpperCase() + response.types[index].type.name.slice(1);
          if(index !== response.types.length - 1) {
            types += ', ';
          }
        }
        setTypes(types);
        setAbility(response.abilities[0].ability.name);
      }
    })
    .catch((err) => {toast("Unown pokemon 😜 ");});
  }

  return (
    <> <section className="pokemon2" id="#pokemon2">
    <form className="search-bar">
        <input 
          id = "search"
          type="search" 
          name="search" 
          required
          placeholder="Search by pokemon name"
          onChange={() => { onChangeHander(); } }
          onKeyDown={(e) => { onKeyDown(e); }}
           />
          <button className="search-btn" type="submit" onClick={(e) => { onSearch(e); }}>
               <span>Search</span>
          </button>
    </form> 
    <div className="pokemon_data">
      <div id="pokemon_image_block" className="pokemon_image_block">
        {data && data.sprites &&
          <img
            src={data.sprites.other.dream_world.front_default}
            alt="pokemon_image"
            id='pokemon_image'>
          </img>}
      </div>
      <div className="pokemon_details">
        {data && data.name !== 'AxiosError' &&
          <ol>
            <li>
              <label className="pokemon_label" htmlFor="pokemon_name">Name: </label>
              <span id="pokemon_name">{data && data.name}</span>
            </li>
            <li>
              <label className="pokemon_label" htmlFor="pokemon_type">Type: </label>
              <span id="pokemon_type">{data && types}</span>
            </li>
            <li>
              <label className="pokemon_label" htmlFor="pokemon_ability">Ability: </label>
              <span id="pokemon_ability">{data && ability}</span>
            </li>
          </ol>}
      </div>
    </div> 
  </section> 
  <ToastContainer 
    position="top-center"
    theme="dark"
    autoClose={3000}
    hideProgressBar={true}
  />
  </>
  );
}

export default Pokemon2;