import React from 'react';
import ProfileHeader from '../components/profileheader/ProfileHeader';
import ProfileDescription from '../components/profiledescription/ProfileDescription';
import Navigation from '../components/navigation/Navigation';
import Experience from '../components/experience/experience';
import About from '../components/about/about';
import Works from '../components/works/works';
import Pokemon from '../components/pokemon/pokemon';
import Pokemon2 from '../components/pokemon/pokemon2';
import PrivacyPolicy from '../components/privacypolicy/privacypolicy';
import { useLocation } from "react-router-dom";
import { Routes, Route } from 'react-router-dom';

export const App = () => {
  const { pathname } = useLocation();
  return (
   <React.Fragment>
    {/**<Experience></Experience>
    <Works></Works>
    <ProfileDescription></ProfileDescription>**/}
    <Routes>
      <Route path ="/" element = {
        <>
          <ProfileHeader />
          <Navigation />
        </>}
      />
      <Route path = "/about" element = { 
        <>
          <About/>
          <Navigation />
        </>}
      />
      <Route path = "/pokemon" element={
        <>
          <Pokemon/>
          <Navigation />
        </>} 
      />
      <Route path = "/pokemon2" element={
        <>
          <Pokemon2/>
          <Navigation />
        </>} 
      />
      <Route path = "/privacypolicy" element = { 
        <>
          <PrivacyPolicy/>
        </>}
      />
    </Routes>
  </React.Fragment>
  )
}
