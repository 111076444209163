import axios from 'axios';

export const getPokemonByName = async (pokemonName) => {
  try {
    const requestOptions = {
      url: 'https://pokeapi.co/api/v2/pokemon/' + pokemonName,
      method: 'GET',
    };
    const response = await axios(requestOptions);
    if (response) {
      return response.data;
    }
    return;
  } catch (err) {
    return err;
  }
}
